<template>
  <div>
    <v-dialog v-model="dialogShowImage" persistent width="600">
      <v-card>
        <v-btn color="pink" dark small absolute right fab @click.native="hideToggleLockJobNumber">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text>
          <v-avatar
          :tile="true"
          :size="500"
          class="grey lighten-4">
            <img :src="utils.imageProfile(selectedImage)">
          </v-avatar>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    <v-form v-model="valid" ref="form">
      <v-row>
        <v-col cols="9">
          <v-row>
              <v-card class="mb-4" flat>
                <v-card-text>
                  <v-container grid-list-xs text-xs-center class="pa-0">
                    <v-row >
                      <v-col cols="3">
                        <v-col cols="12" class="text-xs-center text-sm-center text-md-center text-lg-center">
                          <div>
                            <drop class="drop" @drop="handleDrop">
                              <v-avatar :tile="false" :size="200" class="grey lighten-4 rounded-card"
                                @click="showToggleLockJobNumber(dataUser.picture_base64)">
                                <img :src="utils.imageProfile(dataUser.picture_base64)"/>
                              </v-avatar>
                              <v-btn color="primary" dark @click.native="pickFile">{{$t('login.browse')}}</v-btn>
                              <input type="file" style="display: none" ref="image" accept="image/*" @change="handleFileSelect">
                            </drop>
                          </div>
                        </v-col>
                      </v-col>
                      <v-col cols="9">
                        <v-card>
                          <v-card-text>
                            <v-row >
                              <v-col cols="12" clase="pt-0">
                                <v-text-field outlined dense hide-details
                                  slot="activator"
                                  label="User ID" v-model="dataUser.user_id" color="red"
                                  :readonly="true">
                                </v-text-field>
                              </v-col>
                            </v-row >
                            <v-row >
                              <v-col cols="12" clase="pt-0">
                                <v-text-field 
                                  outlined
                                  dense
                                  hide-details
                                  :label="$t('login.password')"
                                  v-model="dataUser.password"
                                  prepend-icon="lock"
                                  :append-icon="e1 ? 'mdi-eye' : 'mdi-eye-off'"
                                  @click:append="e1 = !e1"
                                  :type="e1 ? 'password' : 'text'"
                                  :hint="$t('login.passwordHint')"
                                  persistent-hint
                                  color="red">
                                </v-text-field>
                              </v-col>
                            </v-row >
                            <v-row >
                              <v-col cols="12" clase="pt-0">
                                <v-text-field outlined dense hide-details
                                  :label="$t('login.newPassword')"
                                  v-model="dataUser.new_password"
                                  prepend-icon="lock"
                                  :append-icon="e4 ? 'mdi-eye' : 'mdi-eye-off'"
                                  @click:append="e4 = !e4"
                                  :type="e4 ? 'password' : 'text'"
                                  :hint="$t('login.passwordHintNew')"
                                  persistent-hint
                                  color="red">
                                </v-text-field>
                              </v-col>
                            </v-row >
                            <v-row >
                              <v-col cols="12" clase="pt-0">
                                <v-text-field outlined dense hide-details
                                  :label="$t('login.confirmNewPassword')"
                                  v-model="dataUser.confirm_new_password"
                                  prepend-icon="lock"
                                  :append-icon="e5 ? 'mdi-eye' : 'mdi-eye-off'"
                                  @click:append="e5 = !e5"
                                  :type="e5 ? 'password' : 'text'"
                                  :hint="$t('login.passwordHintNew')"
                                  persistent-hint
                                  color="red">
                                </v-text-field>
                              </v-col>
                              <v-col cols="6" clase="pt-0 mt-0">
                                <v-text-field outlined dense hide-details v-bind:label="$t('login.firstName')" v-model="dataUser.first_name" color="red"
                                :rules="[v => !!v || 'กรุณากรอกชื่อ']" required>
                                </v-text-field>
                              </v-col>
                              <v-col cols="6" clase="pt-0">
                                <v-text-field outlined dense hide-details :label="$t('login.lastName')" v-model="dataUser.last_name" color="red"
                                :rules="[v => !!v || 'กรุณากรอกนามสกุล']" required>
                                </v-text-field>
                              </v-col>
                              <!-- <v-col cols="6" clase="pa-0">
                                <v-text-field outlined dense hide-details mask="phone" v-model="dataUser.phone" :label="$t('login.mobileNo')"></v-text-field>
                              </v-col> -->
                              <v-col cols="6" clase="pt-0">
                                <v-text-field outlined dense hide-details v-model="dataUser.email" :label="$t('login.eMail')"></v-text-field>
                              </v-col>
                            </v-row >
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" dark @click.native="genQRCode()">Download QR code</v-btn>
                            <v-btn color="primary" dark @click.native="validUserInfo">{{$t('save')}}</v-btn>
                            <v-btn color="primary" dark @click="home">{{$t('cancel')}}</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row >
                  </v-container>
                </v-card-text>
                <!-- <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" dark @click.native="validUserInfo">{{$t('save')}}</v-btn>
                  <v-btn color="primary" dark @click="home">{{$t('cancel')}}</v-btn>
                </v-card-actions> -->
              </v-card>
          </v-row >
        </v-col>
      </v-row >
    </v-form>
    <v-dialog v-model="dialog.dialoggenQRCode" max-width="300px" scrollable eager>
      <v-card>
        <!-- <qrcode-vue :value="valueQrcode" :size="size" level="H" /> -->
        <v-card-title>
          <qr-code :text="valueQrcode" ref="qrCode"></qr-code>
        </v-card-title>
        <v-card-actions>
            <v-btn outlined color="success" @click="saveAsPicture" >save as</v-btn>
            <v-btn outlined color="error" @click="dialog.dialoggenQRCode = false">close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
  .headline{
    margin-top: 1em;
    display: flex;
    img {
      height: 80px;
      width: 80px;
      border-radius: 50%;
    }
  }
  #styled-input {
    height: 40px;
    font-size: 20pt;
  }
  .styled-input label[for] {
    height: 40px;
    font-size: 20pt;
  }
</style>

<script>
  import { mapGetters, mapState } from 'vuex'
  import languagePicker from '../navigation/languagePicker.vue'
  import { USER_REQUEST } from 'actions/user'
  import axios from 'axios'
  import { Drag, Drop } from 'vue-drag-drop'
  import VueQRCodeComponent from 'vue-qrcode-component'
  import html2canvas from 'html2canvas'
  import Base64Binary from 'base64-arraybuffer'

  export default {
    components: {
      languagePicker, Drag, Drop, VueQRCodeComponent,
    },
    data: () => ({
      stringFormat: require('string-format'),
      utils: require('@/utils'),
      dialogShowImage: false,
      dialog: {
        dialoggenQRCode: false,
      },
      selectedImage: null,
      e1: true,
      e3: true,
      e4: true,
      e5: true,
      dataUser: {
      },
      menu2: false,
      date: null,
      sexCodeList: [
        {code: 'M', name: 'ชาย'},
        {code: 'F', name: 'หญิง'}
      ],
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      regPassword: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^0-9a-zA-Z]).{8,26}$/,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }),
    name: 'account',
    computed: {
      ...mapGetters(['getProfile', 'isAuthenticated', 'isProfileLoaded']),
      ...mapState({
        authLoading: state => state.auth.status === 'loading',
        name: state => `${state.user.profile.first_name} ${state.user.profile.last_name}`,
        userID: state => `${state.user.profile.user_id}`,
        token: state => `${state.auth.token}`,
      }),
      computedDateFormatted () {
        if (this.date) {
          return this.formatDate(this.date)
        } else {
          return this.date
        }
      },
    },
    created: function () {
      this.retrieveUserInfo()
    },
    mounted: function () {
    },
    methods: {
      handleDrop (data, event) {
        event.preventDefault()
        const files = event.dataTransfer.files
        const filenames = []
        for (let i = 0; i < files.length; i++) {
          filenames.push(files.item(i).name)
          var reader = new FileReader()
          reader.onload = (e) => {
            var base64Image = e.target.result.split(',')[1]
            this.dataUser.picture_base64 = base64Image
          }
          reader.readAsDataURL(files[i])
        }
      },
      showToggleLockJobNumber (picture) {
        this.dialogShowImage = true
        this.selectedImage = picture
      },
      hideToggleLockJobNumber () {
        this.dialogShowImage = false
      },
      pickFile () {
        this.$refs.image.click()
      },
      handleFileSelect: function (event) {
        var input = event.target
        if (input.files && input.files[0]) {
          var reader = new FileReader()
          reader.onload = (e) => {
            var binaryData = e.target.result
            this.dataUser.picture_base64 = window.btoa(binaryData)
          }
          reader.readAsBinaryString(input.files[0])
          // getBase64(input.files[0]).then(
          //   data => alert(data)
          // )
        }
      },
      retrieveUserInfo () {
        axios.post(process.env.VUE_APP_API + '/user/getUserInfo' + process.env.VUE_APP_DATABASE_TYPE, {
          user_id: this.userID
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.dataUser = response.data.data
              this.dataUser.password = ''
              this.dataUser.confirm_new_password = ''
              this.dataUser.new_password = ''
              this.date = this.dataUser.birthday
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      validateReg () {
        var messageCustom = ''
        if (!this.dataUser.first_name) {
          messageCustom = messageCustom + this.$t('login.messageFirstName') + '<br />'
        }
        if (!this.dataUser.last_name) {
          messageCustom = messageCustom + this.$t('login.messageLastName') + '<br />'
        }
        /* if (!this.dataUser.phone) {
          messageCustom = messageCustom + this.$t('login.messageMobile') + '<br />'
        } */
        /* messageCustom = '<span class="text-xs-center">' + messageCustom + '</span>' */
        if (this.dataUser.email) {
          if (!this.reg.test(this.dataUser.email)) {
            messageCustom = messageCustom + this.$t('login.invalidEmail') + '<br />'
          }
        }
        if (this.dataUser.password === this.dataUser.new_password) {
          messageCustom = messageCustom + 'รหัสผ่านใหม่ต้องไม่ซ้ำกับรหัสผ่านเก่า' + '<br />'
        }
        if (this.dataUser.new_password) {
          if (!this.regPassword.test(this.dataUser.new_password)) {
            messageCustom = messageCustom + this.$t('login.invalidPassword') + '<br />'
          }
        }
        if (messageCustom) {
          this.$swal({type: 'warning', html: messageCustom})
          return false
        } else {
          return true
        }
      },
      validUserInfo () {
        if (this.validateReg() === true) {
          if (this.dataUser.new_password !== this.dataUser.confirm_new_password) {
            this.$swal({type: 'warning', title: 'New Password mismatch.'})
          } else {
            if (this.dataUser.new_password.length > 0) {
              axios.post(process.env.VUE_APP_API + '/user/validateUser' + process.env.VUE_APP_DATABASE_TYPE, {
                user_id: this.dataUser.user_id,
                password: this.dataUser.password
              })
                .then(response => {
                  if (response.data.isSuccess) {
                    this.saveUserInfo()
                  } else {
                    if (response.data.reasonText === 'Invalid password') {
                      this.$swal({type: 'warning', title: 'รหัสผ่านเก่าไม่ถูกต้อง'})
                    } else {
                      this.$swal({type: 'error', title: response.data.reasonText})
                    }
                  }
                })
                .catch(e => {
                  this.$swal({type: 'error', title: e.message})
                })
            } else {
              this.saveUserInfo()
            }
          }
        }
      },
      saveUserInfo () {
        /* utils.imageProfile(selectedImage) */
        /* if (!this.dataUser.picture_base64) {
          console.log('This is null', 'this.dataUser.picture_base64')
          this.dataUser.picture_base64 = this.utils.imageProfile()
          console.log(this.dataUser.picture_base64, 'this.dataUser.picture_base64')
        } */
        axios.post(process.env.VUE_APP_API + '/user/updateUser' + process.env.VUE_APP_DATABASE_TYPE, {
          user_id: this.dataUser.user_id,
          password: this.dataUser.new_password,
          first_name: this.dataUser.first_name,
          last_name: this.dataUser.last_name,
          picture_base64: this.dataUser.picture_base64,
          birthday: this.date,
          sex: this.dataUser.sex,
          email: this.dataUser.email,
          profile_skey: this.dataUser.profile_skey,
          ssn: this.dataUser.ssn
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
              // this.home()
              this.$store.dispatch(USER_REQUEST).then(() => {
              }).catch((error) => {
                this.$swal({type: 'error', title: error})
              })
            } else {
              if (response.data.reasonText === 'Error1') {
                this.$swal({type: 'warning', title: 'รหัสผ่านใหม่ต้องไม่ซ้ำกับรหัสผ่านเก่า'})
              } else {
                this.$swal({type: 'error', title: response.data.reasonText})
              }
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      home () {
        // this.$router.push('/dashboard')
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      genQRCode (userID) {
        userID = this.userID
        this.axios.post(process.env.VUE_APP_API + '/user/getGlobalUrlRegisterRefCode', {
        })
          .then(response => {
            if (response.data.isSuccess) {
              /* this.ShowForgotPassword = response.data.data */
              this.dialog.dialoggenQRCode = true
              var base64ParmUser = btoa(userID)
              console.log(userID, 'userID')
              console.log(base64ParmUser, 'base64ParmUser')
              this.valueQrcode = this.stringFormat('{}{}?', response.data.data, base64ParmUser)
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      saveAsPicture () {
        console.log('sssssssss')
        // Get the element you want to capture
        const elementToCapture = this.$refs.qrCode.$el
        // Use html2canvas to capture the element as an image
        html2canvas(elementToCapture).then((canvas) => {
          // Convert the canvas to a data URL
          const dataURL = canvas.toDataURL()
          // Create a link element and trigger a download
          const link = document.createElement('a')
          link.href = dataURL
          link.download = 'qrcode.png'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
      },
    }
  }
</script>
